<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card>
      <v-card-title primary-title
        >Add Lead <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="cancelDialog"> Cancel </v-btn>
        <template v-if="uuidexist != null">
          <v-btn color="green darken-1" text @click="gotoPatient">
            {{ "View " + getpatientN }}
          </v-btn>
        </template>
        <v-btn
          :disabled="
            !Createvalid || uuidexist != null || loadingAcciones || !validEmail
          "
          :loading="loadingAcciones"
          color="green darken-1"
          text
          @click="addLead"
        >
          Add
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-alert
            dense
            class="actions"
            type="error"
            :value="uuidexist != null"
          >
            {{ `This ${getpatientN} already exists` }}
          </v-alert>
          <v-row class="texts1">
            <v-col cols="12" sm="6">
              <v-text-field
                label="Name *"
                outlined
                class="rounded"
                dense
                :rules="[rules.required]"
                v-model="newlead.name"
              ></v-text-field
            ></v-col>
            <v-col cols="12" sm="6"
              ><v-text-field
                label="Last Name *"
                class="rounded"
                dense
                :rules="[rules.required]"
                v-model="newlead.last_name"
                outlined
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row class="texts1">
            <v-col cols="12" sm="3">
              <ma-date-picker
                v-model="newlead.birthDate"
                past
                label="DOB"
                :editable="true"
            /></v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="newlead.weight"
                label="Weight (lb)"
                outlined
                dense
            /></v-col>
            <v-col cols="12" sm="3"
              ><v-text-field
                v-model="heightfeet"
                label="Height (Ft)"
                outlined
                dense
            /></v-col>
            <v-col cols="12" sm="3"
              ><v-text-field
                v-model="heightinch"
                label="Height (In)"
                outlined
                dense
            /></v-col>
          </v-row>
          <v-row class="texts1">
            <v-col cols="12" sm="6"
              ><v-text-field
                label="Email *"
                class="rounded"
                dense
                :rules="[rules.email, rules.required]"
                v-model="maillead"
                outlined
              ></v-text-field
            ></v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Phone *"
                class="rounded"
                prepend-inner-icon="mdi-numeric-positive-1"
                autocomplete="tel"
                dense
                :rules="[rules.phone]"
                v-model="phonelead"
                outlined
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row class="texts1">
            <v-col cols="12" sm="3">
              <v-autocomplete
                v-model="newlead.howKnow"
                :loading="loadingRefer"
                :items="references"
                item-text="name"
                item-value="name"
                label="Source"
                outlined
                dense
            /></v-col>
            <v-col cols="12" sm="4">
              <v-layout class="mb-3" align-center>
                <label class="mr-3"><strong>Childrens:</strong> </label>
                <v-radio-group
                  v-model="newlead.children"
                  :rules="[rules.required]"
                  class="mt-0"
                  mandatory
                  row
                  dense
                  hide-details
                >
                  <v-radio :value="true" :label="$t('Yes')"></v-radio>
                  <v-radio :value="false" :label="$t('No')" />
                </v-radio-group>
              </v-layout>
            </v-col>
            <v-col cols="12" sm="5">
              <v-layout class="mb-3" align-center style="max-width: 280px">
                <label class="mr-3"><strong>Gender:</strong> </label>
                <v-radio-group
                  v-model="gender"
                  :rules="[rules.required]"
                  class="mt-0"
                  mandatory
                  row
                  dense
                  hide-details
                  @change="newlead.isMale = $event == 'male' ? true : false"
                >
                  <v-radio value="female" :label="$t('labelFemale')"></v-radio>
                  <v-radio value="male" :label="$t('labelMale')" />
                </v-radio-group>
              </v-layout>
            </v-col>
          </v-row>
          <v-divider class="mb-1 texts2 mx-2"></v-divider>
          <div class="d-flex justify-center text-center">
            <h4>Medical History</h4>
          </div>
          <v-divider class="my-1 mx-2"></v-divider>
          <v-row class="mx-1">
            <v-col cols="12" sm="6">
              <div class="d-flex">
                <v-select
                  class="mr-1"
                  :items="doctors"
                  v-model="newlead.medicalHistory.doctorChoiceUuid"
                  item-text="fullname"
                  item-value="uuid"
                  outlined
                  dense
                  label="Doctor of Choice"
                ></v-select></div
            ></v-col>
            <v-col cols="12" sm="6">
              <div class="d-flex">
                <v-select
                  class="mr-1"
                  :items="types"
                  v-model="newlead.procedureInterest"
                  item-text="name"
                  item-value="name"
                  outlined
                  dense
                  label="Procedure(s) of Interest"
                ></v-select></div
            ></v-col>
          </v-row>
          <v-divider class="my-1 texts mx-2"></v-divider>
          <v-row class="mx-1">
            <v-col cols="12" sm="6">
              <v-textarea
                auto-grow
                v-model="newlead.medicalHistory.medicalCondition"
                rows="1"
                row-height="65"
                outlined
                dense
                label="Medical Condition"
              ></v-textarea>
            </v-col>

            <v-col cols="12" sm="6">
              <v-textarea
                auto-grow
                v-model="newlead.medicalHistory.alergies"
                rows="1"
                row-height="65"
                outlined
                dense
                label="Alergies"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-divider class="my-1 texts mx-2"></v-divider>
          <v-row class="mx-1">
            <v-col cols="12" sm="6">
              <v-textarea
                auto-grow
                v-model="newlead.medicalHistory.previosSurgery"
                rows="1"
                row-height="65"
                outlined
                dense
                label="Previous surgeries"
              ></v-textarea>
            </v-col>

            <v-col cols="12" sm="6">
              <v-textarea
                auto-grow
                v-model="newlead.medicalHistory.currentMedications"
                rows="1"
                row-height="65"
                outlined
                dense
                label="Current medications"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-divider class="my-1 texts mx-2"></v-divider>
          <v-row class="mx-1 texts">
            <v-col cols="12" sm="4">
              <v-switch
                v-model="newlead.medicalHistory.smoking"
                inset
                label="Smoking"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="4">
              <v-switch
                v-model="newlead.medicalHistory.alcohol"
                inset
                label="Alcohol"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="4">
              <v-switch
                v-model="othersubstances"
                inset
                label="Other Substances"
              ></v-switch>
            </v-col>
          </v-row>
          <template v-if="othersubstances">
            <v-row class="mx-1 texts2">
              <v-col cols="12" sm="12">
                <v-textarea
                  auto-grow
                  v-model="newlead.medicalHistory.otherSubstances"
                  rows="1"
                  row-height="65"
                  outlined
                  dense
                  label="Details"
                ></v-textarea>
              </v-col>
            </v-row>
          </template>
          <v-row class="mx-1 texts2">
            <v-col cols="12" sm="12">
              <v-textarea
                auto-grow
                v-model="newlead.medicalHistory.details"
                rows="1"
                row-height="65"
                outlined
                dense
                label="Notes"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { notifyError, notifySuccess } from "@/components/Notification";
import rules from "@/components/account/rules";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { getAPI } from "@/api/axios-base";
import moment from "moment";
export default {
  name: "add-lead",
  components: { MaDatePicker },
  data() {
    return {
      loadingAcciones: false,
      rules: {
        email: rules.email,
        required: rules.required,
        phone: rules.isPhone,
        maxCharsfull: (v) => v.length >= 3 || this.$t("min3Chars"),
      },
      types: [],
      gender: "female",
      othersubstances: false,
      phonelead: "",
      maillead: "",
      uuidexist: null,
      heightinch: 0,
      heightfeet: 0,
      newlead: {
        howKnow: "",
        name: "",
        last_name: "",
        phone: "",
        email: "",
        weight: 0,
        height: 0,
        isMale: false,
        birthDate: "",
        children: false,
        procedureInterest: "",
        medicalHistory: {
          doctorChoiceUuid: null,
          smoking: false,
          alcohol: false,
          otherSubstances: "",
          medicalCondition: "",
          alergies: "",
          previosSurgery: "",
          currentMedications: "",
          details: "",
        },
      },

      formComposition: {
        medicalCondition: {
          name: "medicalCondition",
          label: "Do you have any medical conditions?",
          active: true,
        },
        medications: {
          name: "medications",
          label: "Do you take any medications?",
          active: true,
        },
        alergies: {
          name: "alergies",
          label: "Alergies",
          active: true,
        },
        doctorChoise: {
          name: "doctorChoise",
          label: "Doctor of Choice",
          active: true,
        },
        procedureInterest: {
          name: "procedureInterest",
          label: "Procedure of Interest",
          active: true,
        },
        bloodClots: {
          name: "bloodClots",
          label: "Have you ever had any blood clots/ blood clotting?",
          label2: "If yes, please describe the situation and when it happened",
          active: true,
        },
        previousSurgery: {
          name: "previousSurgery",
          label: "Any procedures or surgeries in the last 3 months?",
          label2: "Please specify.",
          active: true,
        },
        buttshots: {
          name: "buttshots",
          label: "Do you have butt shots/ injections?",
          active: true,
        },
        children: {
          name: "children",
          label: "Do you have Children?",
          active: true,
        },
        smoke: {
          name: "smoke",
          label: "Do you smoke or use any nicotine products?",
          active: true,
        },
        alcohol: {
          name: "alcohol",
          label: "Do you drink alcohol?",
          active: true,
        },
        other: {
          name: "other",
          label: "Other Substances",
          label2: "Substances",
          active: true,
        },
        desireSurgeryDate: {
          name: "desireSurgeryDate",
          label: "Desired Surgery Date",
          active: true,
        },
        source: {
          name: "source",
          label: "Please tell us how you heard about us",
          active: true,
        },
        details: {
          name: "details",
          label: "Notes",
          active: true,
        },
        photo: {
          name: "photo",
          label: "Photos",
          label1: "Attach files",
          active: true,
        },
        besttime: {
          name: "besttime",
          label: "Best time to call",
          label1: "(office hours 9am- 6pm, M-F)",
          active: true,
        },
        emailme: {
          name: "emailme",
          label: "Email me a copy of my responses.",
          label1:
            "We’ll only use your email address to send you your responses. Once you submit your responses, you’ll be sent to our sign-up page.",
          active: true,
        },
      },
    };
  },
  watch: {
    phonelead: {
      async handler() {
        this.newlead.phone = this.phonelead;
        if (this.phonelead.length == 10) {
          await this.actFilterPatient({
            query: this.phonelead,
            range: { limit: 100, offset: 0 },
          });
          if (this.patients.length != 0) {
            this.uuidexist = this.patients[0].uuid;
          } else {
            this.uuidexist = null;
          }
        } else if (this.phonelead.length == 0) {
          this.uuidexist = null;
        }
      },
    },
    maillead: {
      async handler() {
        this.newlead.email = this.maillead;
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (pattern.test(this.maillead)) {
          await this.actFilterPatient({
            query: this.maillead,
            range: { limit: 100, offset: 0 },
          });
          if (this.patients.length != 0) {
            this.uuidexist = this.patients[0].uuid;
          } else {
            this.uuidexist = null;
          }
        } else {
          this.uuidexist = null;
        }
      },
    },
  },
  computed: {
    ...mapState(["configForm"]),
    ...mapState("crmSettingsModule", ["loadingRefer", "references"]),
    ...mapState("crmEmployeeModule", ["doctors", "loading"]),
    ...mapGetters(["getCoordinatorN", "getpatientN"]),
    ...mapState("crmMedicFormModule", ["patients"]),
    validEmail() {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (pattern.test(this.newlead.email)) {
        return true;
      }
      return false;
    },
    Createvalid() {
      if (
        this.newlead.name != "" &&
        this.newlead.last_name != "" &&
        this.newlead.email != "" &&
        this.newlead.phone != "" &&
        this.newlead.phone.length == 10
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actFilterPatient"]),
    ...mapActions("crmSettingsModule", ["actListReferences"]),
    ...mapActions("crmEmployeeModule", ["actGetPatientDoctors"]),
    cancelDialog() {
      this.$router.push("/leads_center");
    },
    gotoPatient() {
      this.$router.push(`/patients/details/${this.uuidexist}`);
    },
    addLead() {
      this.loadingAcciones = true;
      let body = Object.assign({}, this.newlead);
      body.phone = "+1" + body.phone;
      body.weight = Number(body.weight);
      body.height = Number(this.heightfeet + "." + this.heightinch);

      body = this.cleanNull(body);
      body.medicalHistory = this.cleanNull(body.medicalHistory);
      if (body.birthDate) {
        body.birthDate = moment(body.birthDate).utc().toISOString();
      }

      getAPI
        .post("/social/createLead/", body)
        .then(() => {
          this.cancelDialog();
          notifySuccess(`The Lead was added successfully`);
        })
        .catch((error) => {
          this.loadingAcciones = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    cleanNull(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
    loadProcedures() {
      getAPI("/procedure-form-nom/list").then((res) => {
        this.types = res.data;
      });
    },
  },
  mounted() {
    this.actListReferences();
    this.loadProcedures();
    this.actGetPatientDoctors();
    localStorage.setItem("leadcenter", true);
  },
};
</script>
<style lang=""></style>
